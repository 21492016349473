import React, { useEffect, useState } from 'react';
import Expense from './Expense';
import { EditModal, DeleteModal } from './Modals';
import C from './../../config';
import { mkConfig, generateCsv, download } from "export-to-csv";

// mkConfig merges your options with the defaults
// and returns WithDefaults<ConfigOptions>
const csvConfig = mkConfig({ useKeysAsHeaders: true });

const api = C.projects.core.api

function ExpenseAdmin(props) {
    const [list, setList] = useState([]);
    const [selected, setSelected] = useState();
    const [showModal, setShowModal] = useState(false);
    const [showSupervisorModal, setShowSupervisorModal] = useState(false);
    const [showDelModal, setShowDelModal] = useState(false);
    const [modalSelected, setModalSelected] = useState();
    const [supervisorList, setSupervisorList] = useState();

    useEffect(() => {
        fetchFilterExpensesList();
    }, [])

    async function read(supervisors) {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': props.token
            },
        };
        const uri = supervisors ? `${api}/expenses/supervisors` : `${api}/expenses`;
        const res = await fetch(uri, requestOptions)
        let resJson = await res.json();
        return resJson;
    }

    async function update(obj) {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(obj)
        };
        const createRes = await fetch(`${api}/expenses/${obj._id}`, requestOptions);
        window.toast(createRes.status == 200 ? "Expense Report Updated" : "Unauthorized access.");
    }

    async function sendFinance(obj) {
        await setSelected(obj)
        const newObj = obj;
        await props.generatePDF(newObj.name, newObj.entries, true, true, (pdf) => {
            newObj.pdf64 = pdf;
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(newObj)
            };
            const createRes = fetch(`${api}/expenses/finance`, requestOptions);
            window.toast(createRes.status == 200 ? "Expense Report sent to Finance" : "Unauthorized access.");
        })
    }

    async function del(id) {
        await fetch(`${api}/expenses/${id}`, { method: 'delete' });
        await fetchFilterExpensesList();
        window.toast("Expense Report Deleted");
    }

    async function fetchFilterExpensesList() {
        let loadedList = await read();
        if (props.view === 'draft') {
            loadedList = loadedList.filter(f => f.email === props.user.email && f.draft)
        }
        if (props.view === 'history') {
            loadedList = loadedList.filter(f => f.email === props.user.email && !f.draft)
        }
        if (props.view === 'supervisor') {
            loadedList = loadedList.filter(f => f.supervisorAssigned === props.user.email)
        }
        if (props.view === 'admin') {
            loadedList = loadedList.filter(f => !f.draft)
            const supListRaw = await read(true)
            setSupervisorList(supListRaw)
        }
        setList(loadedList)
    }

    async function signedAndDone(signedObj) {
        await update(signedObj);
        await fetchFilterExpensesList();
        window.toast("Expense Report Updated");
    }

    function selEdit(el) {
        setModalSelected(el)
        setShowModal(true);
    }

    function selSupervisor(el) {
        setModalSelected(el)
        setShowSupervisorModal(true);
    }

    function confirmDel(el) {
        setModalSelected(el)
        setShowDelModal(true);
    }

    function exportCsv() {
        const exportFormat = [...list];
        exportFormat.forEach(l => {
            l.signature = !!l.signature
            l.supervisorSignature = !!l.supervisorSignature
            l.amount = l.entries.reduce((accum, item) => accum + item.amount, 0);
            delete l.entries
        });
        const csv = generateCsv(csvConfig)(exportFormat);
        download(csvConfig)(csv)
    }

    function expensesTable() {
        return <div className='row'>
            {showModal && <EditModal show={showModal} selected={modalSelected} onClose={(updateObj) => {
                if (updateObj) update(updateObj);
                setShowModal(false)
            }} />}
            {showDelModal && <DeleteModal show={showDelModal} selected={modalSelected} onClose={(idDel) => {
                if (idDel) del(idDel);
                setShowDelModal(false)
            }} />}
            {props.view === 'admin' && showSupervisorModal && <EditModal assign={true} show={showSupervisorModal} supervisorList={supervisorList} selected={modalSelected} onClose={(updateObj) => {
                if (updateObj) update(updateObj);
                setShowSupervisorModal(false);
            }} />}
            <div className="App tableFixHead">
                <button type="button" style={{ margin: '2% 0' }} className="btn btn-warning" onClick={exportCsv}>Export</button>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Id</th>
                            <th>Email</th>
                            <th>Name</th>
                            <th>Department</th>
                            <th>Purpose</th>
                            <th>Signed by employee</th>
                            <th>Supervisor</th>
                            <th>Signed by supervisor</th>
                            <th>Signed by supervisor on</th>
                            <th>Amount</th>
                            <th>External</th>
                            {(props.view === 'draft' || props.view === 'admin') ? <th style={{width: '260px'}}>Options</th> : null}
                        </tr>
                    </thead>
                    <tbody>
                        {list.map(el => (
                            <tr className={`expenseRow ${selected?.id === el.id ? 'selected' : ''}`} key={el.id} style={{ height: '3em' }}>
                                <td onClick={() => setSelected(el)}>{(new Date(parseInt(el._id.toString().substring(0,8), 16) * 1000)).toLocaleDateString('de-DE', {timeZone: 'UTC'})}</td>
                                <td onClick={() => setSelected(el)}>{el.id}</td>
                                <td onClick={() => setSelected(el)}>{el.email}</td>
                                <td onClick={() => setSelected(el)}>{el.name}</td>
                                <td onClick={() => setSelected(el)}>{el.department}</td>
                                <td onClick={() => setSelected(el)}>{el.purpose}</td>
                                <td onClick={() => setSelected(el)} style={{ background: el.signature ? 'green' : 'yellow' }}>{el.signature ? 'true' : 'false'}</td>
                                <td onClick={() => setSelected(el)}>{el.supervisorAssigned}</td>
                                <td onClick={() => setSelected(el)} style={{ background: el.supervisorSignature ? 'green' : 'yellow' }}>{el.supervisorSignature ? 'true' : 'false'}</td>
                                <td onClick={() => setSelected(el)}>{el.supervisorDate ? (new Date(el.supervisorDate).toLocaleDateString('en-CA', {timeZone: 'UTC'})) : null}</td>
                                <td onClick={() => setSelected(el)}>{el.entries && el.entries.length ? el.entries.reduce((accum, item) => accum + item.amount, 0): "N/A"}</td>
                                <td onClick={() => setSelected(el)}>{el.entries && el.entries.length ? el.entries.some(it => it.external).toString(): false.toString()}</td>
                                {(props.view === 'draft' || props.view === 'admin') ? <td>
                                    {(props.view === 'admin') && <button style={{marginRight: '2px'}} type="button" className="btn btn-light" onClick={() => selEdit(el)} title="Edit"><i className="bi bi-pencil-square"/></button>}
                                    {props.view === 'admin' && <button style={{marginRight: '2px'}} type="button" disabled={el.supervisorAssigned} className="btn btn-warning" onClick={() => selSupervisor(el)} title="Assign supervisor"><i className="bi bi-person-fill-check"/></button>}
                                    {props.view === 'admin' && <button style={{marginRight: '2px'}} type="button" className="btn btn-success" onClick={() => sendFinance(el)} title="Send to Finance"><i className="bi bi-file-binary"/></button>}
                                    {(el.draft || props.view === 'admin') && <button style={{marginRight: '2px'}} type="button" className="btn btn-danger" onClick={() => confirmDel(el)} title="Remove"><i className="bi bi-trash"/></button>}
                                </td> : null}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    }
    return <div>
        {expensesTable()}
        {selected && <Expense selected={selected} onDraftOrSupervisor={signedAndDone} user={props.user} generatePDF={props.generatePDF} view={props.view} />}
    </div>
}

export default ExpenseAdmin;