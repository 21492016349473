import React, { useState, useRef, useEffect } from 'react';
import SignaturePad from "react-signature-canvas";
import { JsonEditor } from 'json-edit-react'
import C from './../../config';
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

// global worker
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
).toString();

const currencies = [{
    "iso": "EUR",
    "name": "Euro",
    "symbol": "€",
}, {
    "iso": "GBP",
    "name": "Pound Sterling",
    "symbol": "£",
}, {
    "iso": "USD",
    "name": "United States Dollar",
    "symbol": "$",
}, {
    "iso": "Other",
    "name": "Other",
    "symbol": "Other",
}
];

export const UploadModal = (props) => {
    const [show, setShow] = useState(props.show || false);
    const [image, setImage] = useState(null);

    const handleClose = () => {
        props.onCancel()
        setShow(false);
    }

    const onFinish = () => {
        props.onUpload(image)
        setShow(false);
    }

    const uploadImage = (img) => {
        if (img.size > 1024 * 1024 * 5) {
            alert("File is too big! (Max 5MB)");
        } else {
            setImage(img)
        }
    }

    return (
        <>
            <div className="modal top fade show" style={{ display: show ? 'block' : 'none', background: show ? '#000000c2' : 'none' }} id="exampleModal" tabIndex="-1" data-mdb-backdrop="true" data-mdb-keyboard="true">
                <div className="modal-dialog  ">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Upload receipt (Only PDF, PNG and JPG/JPEG formats allowed)</h5>
                            <button type="button" className="btn-close" data-mdb-dismiss="modal" onClick={handleClose} ></button>
                        </div>
                        <div className="modal-body">
                            <div className="card-body p-5 text-center">

                                <div className="input-group mb-3">
                                    <input type="file" className="form-control" id="inputGroupFile01" name="upfile" accept=".png, .jpg, .jpeg, .pdf" onChange={(event) => uploadImage(event.target.files[0])} />
                                </div>

                                <button type="button" className="btn btn-primary" data-mdb-dismiss="modal" onClick={onFinish} disabled={!image}>
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export const EntryModal = (props) => {
    const [show, setShow] = useState(props.show || false);
    const [category, setCategory] = useState('Travel');
    const [description, setDescription] = useState('');
    const [date, setDate] = useState(new Date().toISOString().slice(0, 10));
    const [amount, setAmount] = useState(1);
    const [showUploadModal, setShowUploadModal] = useState(false)
    const [participants, setParticipants] = useState(null)
    const [images, setImages] = useState([]);
    const [currency, setCurrency] = useState('EUR');
    const [external, setExternal] = useState(false);

    useEffect(() => {
        if (props.selected) reload(props.selected)
    }, [])

    function reload(item) {
        setCategory(item.category)
        setDescription(item.description)
        setDate(item.date)
        setAmount(item.amount)
        setParticipants(item.participants)
        setImages(item.images)
        setCurrency(item.currency)
        setExternal(item.external)
    }

    const handleClose = () => {
        props.onCancel()
        setShow(false);
    }

    async function onRun() {
        const newEntry = { category, description, date, amount, participants, images, currency, external };
        if (props.selected && props.selected.id) {
            newEntry.id = props.selected.id;
            props.onConfirm(newEntry, true)
        } else {
            newEntry.id = Math.random().toString(36).slice(2);
            props.onConfirm(newEntry)
        }
        setShow(false);
    }

    return (
        <>
            <div className="modal top fade show" style={{ display: show ? 'block' : 'none', background: show ? '#000000c2' : 'none' }} id="exampleModal" tabIndex="-1" data-mdb-backdrop="true" data-mdb-keyboard="true">
                <div className="modal-dialog  ">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">New Expense Entry</h5>
                            <button type="button" className="btn-close" data-mdb-dismiss="modal" onClick={handleClose} ></button>
                        </div>
                        <div className="modal-body">
                            <div className="card-body p-5 text-center">
                                <form className="mb-md-5 mt-md-4 pb-5">

                                    <div className="row input-group" style={{ width: '100%', padding: '1%' }}>
                                        <label className="form-check-label" htmlFor="bannerSelect">
                                            Category
                                        </label>
                                        <select className="form-select" required id="bannerSelect" defaultValue={category} onChange={e => setCategory(e.target.value)}>
                                            <option value='Travel'>Travel</option>
                                            <option value="Lodging">Lodging</option>
                                            <option value="Entertainment">Entertainment</option>
                                            <option value="Per Diem">Per Diem</option>
                                            <option value="Communication">Communication</option>
                                            <option value="Misc">Misc</option>
                                        </select>
                                    </div>

                                    <label style={{ margin: '0 1%', float:'left', display:'block' }}>
                                        <input className="form-check-input" type="checkbox" value={external} checked={!!external} onChange={(e) => setExternal(!external)} />
                                        {' '} External people participated
                                    </label>
                                    {external ? <span><p style={{display: 'block', fontStyle: 'italic'}}>If external people participated, you must attach a hospitality receipt (Bewirtungsbeleg).</p></span> : null }

                                    <div className="row input-group" style={{ width: '100%', padding: '1%' }}>
                                        <input type="email" required className="form-control" id="exampleFormControlInput1" placeholder="Description" value={description} onChange={e => setDescription(e.target.value)} />
                                    </div>
                                    <div className="row input-group" style={{ width: '100%', padding: '1%' }}>
                                        <input type="date" required className="form-control" id="exampleFormControlInput1" placeholder="Date" value={date} onChange={e => setDate(e.target.value)} />
                                    </div>
                                    <div className="row input-group" style={{ width: '100%', padding: '1%' }}>
                                        <input type="number" required min="1" className="form-control" id="exampleFormControlInput1" placeholder="Amount" value={amount} onChange={e => setAmount(parseFloat(e.target.value))} />
                                        <select className="form-select" required id="bannerSelect" defaultValue={category} onChange={e => setCurrency(e.target.value)}>
                                            {currencies.map(c => (<option key={c.iso} value={c.iso}>{c.iso}({c.symbol})</option>))}
                                        </select>
                                    </div>
                                    <div className="row input-group mt-2">
                                        <label htmlFor="floatingTextarea2">Notes:</label>
                                        <textarea className="form-control" placeholder="e.g. participants of your dinner (split by comma), any additional note" id="floatingTextarea2" onChange={(e) => setParticipants(e.target.value)}></textarea>
                                    </div>

                                    <div className="row input-group mt-2">
                                        <label htmlFor="floatingTextarea2">Bills</label>
                                        {images.length ? <ul>{images.map(i => <li key={i.name || i}><p>{i.name || i}</p></li>)}</ul> : null}
                                        {!props.view ? <button type="button" style={{ marginBottom: '2%' }} className="btn btn-warning" data-mdb-dismiss="modal" onClick={() => setShowUploadModal(true)}>
                                            + Add a recipt/s for this expense
                                        </button> : <i>*At the moment it is not possible to modify images once the expense has been created/drafted.</i>}
                                    </div>

                                    {showUploadModal && <UploadModal show={showUploadModal} onUpload={(newEntry) => { setImages([...images, newEntry]); setShowUploadModal(false) }} onCancel={() => null} />}

                                    <button type="button" className="btn btn-primary mt-2" data-mdb-dismiss="modal" onClick={onRun} disabled={!category || !description || !date || !amount}>
                                        Add
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export const SignatureModal = (props) => {
    const [show, setShow] = useState(props.show || false);
    const [drawMode, setDrawMode] = useState(false);
    const [imageURL, setImageURL] = useState(null); // create a state that will contain our image url

    const sigCanvas = useRef({});

    /* a function that uses the canvas ref to clear the canvas 
    via a method given by react-signature-canvas */
    const clear = () => sigCanvas.current.clear();

    /* a function that uses the canvas ref to trim the canvas 
    from white spaces via a method given by react-signature-canvas
    then saves it in our state */
    const save = () => {
        const image = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
        setImageURL(image);
        props.onConfirm(image)
        setShow(false);
    }

    const saveName = async () => {
        props.onConfirm(props.name)
        setShow(false);
    }

    const handleClose = () => {
        props.onCancel()
        setShow(false);
    }

    return (
        <>
            <div className="modal top fade show" style={{ display: show ? 'block' : 'none', background: show ? '#000000c2' : 'none' }} id="exampleModal" tabIndex="-1" data-mdb-backdrop="true" data-mdb-keyboard="true">
                <div className="modal-dialog" style={{ width: '80%' }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">New Signature</h5>
                            <button type="button" className="btn-close" data-mdb-dismiss="modal" onClick={handleClose} ></button>
                        </div>
                        <div className="modal-body">
                            <div className="card-body p-5 text-center">
                                <form className="mb-md-5 mt-md-4 pb-5">
                                    {!drawMode ? <div>
                                        <p className='signature1' id="Sign">{props.name}</p>
                                        <button type="button" className="btn btn-primary" onClick={saveName} >Save</button>
                                        <button type="button" className="btn btn-secondary" onClick={() => setDrawMode(true)} >Draw</button>
                                    </div> : <div>
                                        <SignaturePad
                                            ref={sigCanvas}
                                            canvasProps={{
                                                className: "signatureCanvas"
                                            }}
                                        />
                                        {/* Button to trigger save canvas image */}
                                        <button type="button" className="btn btn-primary" onClick={save}>Save</button>
                                        <button type="button" className="btn btn-secondary" onClick={clear}>Clear</button>
                                        <button type="button" className="btn btn-danger" onClick={handleClose}>Close</button>
                                    </div>
                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export const ImageModal = (props) => {
    const [show, setShow] = useState(props.show || false);

    const handleClose = () => {
        props.onClose()
        setShow(false);
    }

    const isImage = (im) => props.isSubView ? true : !im.name.includes('pdf');

    return (
        <>
            <div className="modal top fade show" style={{ display: show ? 'block' : 'none', background: show ? '#000000c2' : 'none' }} id="exampleModal" tabIndex="-1" data-mdb-backdrop="true" data-mdb-keyboard="true">
                <div className="modal-dialog" style={{ width: '80%' }}>
                    <div className="modal-content" style={{ width: '70rem' }}>
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Image viewer</h5>
                            <button type="button" className="btn-close" data-mdb-dismiss="modal" onClick={handleClose} ></button>
                        </div>
                        <div className="modal-body">
                            <div className="card-body p-5 text-center">
                                {props.target.map(i => <div key={props.isSubView ? i : URL.createObjectURL(i)}>
                                    {isImage(i) ? <img
                                        className="images"
                                        alt="not found"
                                        width={"400px"}
                                        src={props.isSubView ? C.projects.core.api + '/' + i : URL.createObjectURL(i)}
                                    />
                                        :
                                        <Document file={URL.createObjectURL(i)}>
                                            <Page pageNumber={1} />
                                        </Document>
                                    }
                                    -
                                </div>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export const DeleteModal = (props) => {
    const [show, setShow] = useState(props.show || false);

    const handleClose = (affirmative) => {
        props.onClose(affirmative ? props.selected._id : null)
        setShow(false);
    }

    return (
        <>
            <div className="modal top fade show" style={{ display: show ? 'block' : 'none', background: show ? '#000000c2' : 'none' }} id="exampleModal" tabIndex="-1" data-mdb-backdrop="true" data-mdb-keyboard="true">
                <div className="modal-dialog" style={{ width: '80%' }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Delete Expense</h5>
                            <button type="button" className="btn-close" data-mdb-dismiss="modal" onClick={() => handleClose()} ></button>
                        </div>
                        <div className="modal-body">
                            <div className="card-body p-5 text-center">
                                Are you sure you want to Delete <b>{props.selected.id}</b> ?
                            </div>
                            <button type="button" style={{ margin: '2% 0' }} className="btn btn-primary" onClick={() => handleClose(true)}>Yes</button>
                            <button type="button" style={{ margin: '2% 0' }} className="btn btn-secondary" onClick={() => handleClose()}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export const EditModal = (props) => {
    const [show, setShow] = useState(props.show || false);
    const [assigned, setAssigned] = useState(null);
    const [updatedObject, setUpdatedObject] = useState(props.selected);

    const handleClose = (upObj) => {
        props.onClose(upObj || null)
        setShow(false);
    }

    const onRun = async () => {
        let modifiedObj;
        if (props.assign) {
            modifiedObj = props.selected;
            modifiedObj.supervisorAssigned = assigned;
        } else {
            modifiedObj = updatedObject.newData;
        }
        handleClose(modifiedObj);
    }

    return (
        <>
            <div className="modal top fade show" style={{ display: show ? 'block' : 'none', background: show ? '#000000c2' : 'none' }} id="exampleModal" tabIndex="-1" data-mdb-backdrop="true" data-mdb-keyboard="true">
                <div className="modal-dialog modal-xl" style={{ width: '100%' }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Edit expense</h5>
                            <button type="button" className="btn-close" data-mdb-dismiss="modal" onClick={() => handleClose()} ></button>
                        </div>
                        <div className="modal-body">
                            <div className="card-body p-5 text-center">
                                {!props.assign ? <JsonEditor
                                    data={updatedObject}
                                    onUpdate={setUpdatedObject}
                                    maxWidth={'100%'}
                                /> : <select className="form-select" required id="bannerSelect" onChange={e => setAssigned(e.target.value)}>
                                    {props.supervisorList.map((em, i) => <option key={em + i} value={em}>{em}</option>)}
                                </select>}
                            </div>

                            <button type="button" className="btn btn-primary mt-2" data-mdb-dismiss="modal" onClick={onRun}>
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}